import { toast } from "react-toastify";

export const SHOW_TOAST = (message, type = 'info') => {
    toast(message, {
        type: type,
        position: "bottom-left",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
};
