import React from 'react'
import { BrowserRouter, Routes, Route, Navigate, Outlet } from 'react-router-dom'
import './App.css'
import Home from './Components/Home/Home'
import Header from './Components/Navigation/Header'
import AddQuestion from './Components/AddQuestion/AddQuestion'
import ReportedQuestions from './Components/ReportedQuestions/ReportedQuestions'
import TopicQuestions from './Components/TopicQuestions/TopicQuestions'
import Login from './Components/Login/Login'
import isAuthenticated from './Auth/AuthenticateUser'
import DeletedTopics from './Components/DeletedTopics/DeletedTopics'
import EditQuestion from './Components/EditQuestion/EditQuestion'
import EditTopic from './Components/EditTopic/EditTopic'
import AddExam from './Components/AddExam/AddExam'
import ViewExams from './Components/ViewExams/ViewExams'
import EditExam from './Components/EditExam/EditExam'
import EditUser from './Components/EditUser/EditUser'
import ViewTopics from './Components/ViewTopics/ViewTopics'
import AddSubtopic from './Components/AddSubtopic/AddSubtopic'
import ViewSubtopics from './Components/ViewSubtopics/ViewSubtopics'
import EditSubtopic from './Components/EditSubtopic/EditSubtopic'
import ViewSection from './Components/ViewSection/ViewSection'
import ViewSectionTopics from './Components/ViewSectionTopics/ViewSectionTopics'
import QuestionsList from './Components/QuestionsList/QuestionsList'
import DeleteQuestion from './Components/DeleteQuestion/DeleteQuestion'
import AddSubject from './Components/AddSubject/AddSubject'
import AddQuestionWithExcel from './Components/AddQuestionWithExcel/AddQuestionWithExcel'
import EditSubject from './Components/EditSubject/EditSubject'
import SubjectComponent from './Components/SubjectComponent/SubjectComponent'
import TopicComponent from './Components/TopicComponent/TopicComponent'
import ExamComponent from './Components/ExamComponent/ExamComponent'
import ExcelAdd from './Components/ExcelAdd/ExcelAdd'
import EditResource from './Components/Quiz/EditResource'
import ManagementSubject from './Components/ManagementSubject/ManagementSubject'
import TopicManagement from './Components/ManagementTopic/ManagementTopic'
import ReorderQuestions from './Components/ReorderQuestions/ReorderQuestions'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import ManagementExam from './Components/Quiz/ManagementExam'
import SearchQuestion from './Components/SearchQuestion/SearchQuestion'
import AddExamV2 from './Components/AddExamV2/AddExamV2'
import AddQuizV2 from './Components/Quiz/AddQuizV2'
import ImageUpload from './Components/ImageUpload/ImageUpload'
import ReorderQuiz from './Components/Quiz/ReorderQuiz'
import AddCourse from './Components/Course/AddCourse'
import CourseManagement from './Components/Course/CourseManagement'
import CourseTopicsManagement from './Components/Course/CourseTopicsManagement'
import CourseTopicInfo from './Components/Course/CourseTopicInfo'
import MediaUpload from './Components/Features/MediaUpload'
import ReorderCourseTopics from './Components/Course/ReorderCourseTopics'
import ContactUsForm from './Components/Features/ContactUsForm'
import UserManagement from './Components/Users/Users'

function App() {
    const PrivateWrapper = () => {
        return isAuthenticated() ? <Outlet /> : <Navigate to='/login' />
    }
    return (
        <BrowserRouter>
            <div>
                <ToastContainer />
                <Header />
                <Routes>
                    <Route exact path='/login' element={<Login />} />
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/' element={<Home />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/add-question' element={<AddQuestion />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/add-excel-questions/:id' element={<AddQuestionWithExcel />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/add-subject' element={<AddSubject />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/add-subtopic' element={<AddSubtopic />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/subjects' element={<ViewTopics />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/section' element={<ViewSection />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/topic' element={<ViewSectionTopics />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/exams' element={<ViewSubtopics />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/questions-list' element={<QuestionsList />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/users' element={<UserManagement />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/topic-questions/:id' element={<TopicQuestions />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/reported-questions' element={<ReportedQuestions />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/deleted-topics' element={<DeletedTopics />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/edit-question' element={<EditQuestion />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/edit-topic' element={<EditTopic />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/edit-subject' element={<EditSubject />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/edit-subtopic' element={<EditSubtopic />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/add-exam' element={<AddExam />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/add-exam-v2' element={<AddExamV2 />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/view-exams' element={<ViewExams />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/exam' element={<EditExam />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/edit-user' element={<EditUser />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/delete-question' element={<DeleteQuestion />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/subject/:id' element={<SubjectComponent />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/topic/:id' element={<TopicComponent />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/exam/:id' element={<ExamComponent />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/reorder-questions/:id' element={<ReorderQuestions />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/excel-add' element={<ExcelAdd />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/edit-resource' element={<EditResource />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/subject-management' element={<ManagementSubject />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/topic-management' element={<TopicManagement />} />
                    </Route>

                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/add-course' element={<AddCourse />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/courses' element={<CourseManagement />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/course/:id' element={<CourseTopicsManagement />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/course-topic/:id' element={<CourseTopicInfo />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route path="/reorder-course-topic/:id" element={<ReorderCourseTopics />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/exam-management' element={<ManagementExam />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/reorder-quiz' element={<ReorderQuiz />} />
                    </Route>

                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/search-question' element={<SearchQuestion />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/add-quiz-v2' element={<AddQuizV2 />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/image-upload' element={<ImageUpload />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/media-upload' element={<MediaUpload />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/contact-submissions' element={<ContactUsForm />} />
                    </Route>
                </Routes>
            </div>
        </BrowserRouter>
    )
}

export default App
